@font-face {
    font-family: "MV_Faseyha";
    src:url(/fonts/MV_Faseyha_Mhr.otf);
}

// titles
h1 {
	font-family: $mihaaru-font-slab;
	color: $mihaaru-text-color-dark;
	font-size: 24px;
	margin-bottom: 45px;
	line-height: 1.8;
}

// margins
.mt20 {
	margin-top: 20px;
}
.mb20 {
	margin-bottom: 20px;
}

// paddings
.prl40 {
	padding-right: 40px !important;
	padding-left: 40px !important;
}

// thaana
.thaana {
	direction: rtl;
	text-align: right;
  font-family: MV_Faseyha;
}

// cropit
#image-cropper {
	margin-top: 20px;
	overflow: hidden;
}
.cropit-preview {
	width: 200px;
	height: 200px;
	margin: auto;
}
.cropit-controls {
	margin: 20px 40px 10px;
	
	label.btn-link {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
.cropit-preview.cropit-image-loading .spinner {
	opacity: 1;
}
.cropit-preview-image-container {
	background: #eee;
}
.cropit-preview.cropit-image-loaded .cropit-preview-image-container {
	cursor: move;
}
.cropit-image-zoom-input[disabled] {
	opacity: .2;
}
input.cropit-image-input {
	visibility: hidden;
}
.cropit-preview-background {
	opacity: .2;
}
input.cropit-image-zoom-input {
	position: relative;
}


// header
header#header {
	a#header-logo {
		display: inline-block;

		img {
			margin: 40px 0;
			height: 96px;
		}
	}
}

// nav
nav.navbar {
	border: 1px solid $mihaaru-border-color;

	@media screen and (max-width: $mihaaru-max-break-point) {
		border-bottom: none;
	}
	
	.navbar-header {
		@media screen and (max-width: $mihaaru-max-break-point) {
			border-bottom: 1px solid $mihaaru-border-color;
		}

		button {
			span {
				background: $mihaaru-text-color-dark;
			}
		}

		a.navbar-brand {
			text-transform: uppercase;
			font-family: $mihaaru-font-slab;
			color: $mihaaru-text-color-dark;
			font-size: 17px;
		}
	}

	#app-navbar-collapse {

		ul.nav {
		    margin: 0px -15px;
			border-bottom: 1px solid #ddd;

			@media screen and (min-width: $mihaaru-min-break-point) {
			    margin: 0 15px;
			    border-bottom: none;
		    }

		    li {

		    	a {
		    		text-transform: uppercase;
		    		color: $mihaaru-text-color-dark;

		    		@media screen and (min-width: $mihaaru-min-break-point) {
		    			border-left: 1px solid $mihaaru-border-color;
		    		}

		    		@media screen and (min-width: $mihaaru-min-break-point) and (max-width: $mihaaru-seperate-break-point) {
		    			font-size: 12px;
		    		}

		    		&.member {
		    			img {
		    				margin: 0 4px;
		    				
		    				@media screen and (min-width: $mihaaru-min-break-point) {
		    					margin-top: -7px !important;
		    				}
		    			}
		    		}
		    	}
		    }
		}
	}
}

//breadcrumbs
.breadcrumb {
	margin-bottom: 0;
	border-radius: 0;
	background: #fff;
	padding: 8px 0;
}

// form
#form-container {
	div.row {
		margin: 0 20px;

		@media screen and (min-width: 768px) {
			margin: 0 40px;
		}

		input[type=text],
		input[type=password],
		input[type=email] {
			background: #F7F7F7;
			border: 1px solid #E6E6E6;
			border-radius: $mihaaru-border-radius;
			padding: 0 12px;
			height: 44px;
		}

		select {
			width: 100%;
			background: #F7F7F7;
			border: 1px solid #E6E6E6;
			border-radius: $mihaaru-border-radius;
			height: 42px;

			-moz-appearance: none;
			-webkit-appearance: none;
		}
		
		.radio-wrap {
			.radio-inline {
				float: left;
				margin: 0 15px 0 0 !important;

				.radio-btn {
					position: relative;
					float: left;
					
					input {
						position: absolute;
						top: 0;
						left: 0;
						width: auto !important;
						height: 14px;
						border: 0;
						outline: none;
						box-shadow: none;
					}
				}

				.radio-name {
					float: left;
				}
			}
		}

		label {
			color: $mihaaru-text-color-light;
			text-align: left;
		}

		a {
			cursor: pointer;
		}

		a.form-link {
			display: block;
			margin-top: 10px;
		}

		a.form-link-grey {
			padding-top: 0;
			margin-top: 30px;
			color: $mihaaru-text-color-light;
		}

		div.seperator {
			position: relative;
			width: 100%;
			text-align: center;
			height: 40px;
			margin: 40px 0;

			@media screen and (min-width: $mihaaru-seperate-break-point) {
				margin: 0;
				height: 185px;
			}

			div.seperator-line {
				position: absolute;
				width: 100%;
				height: 1px;
				top: 20px;
				background: $mihaaru-border-color;

				@media screen and (min-width: $mihaaru-seperate-break-point) {
					width: 1px;
					height: 100%;
					top: 0;
					left: 50%;
				}
			}

			div.seperator-text {
			    position: absolute;
			    width: 40px;
			    left: 50%;
			    margin-left: -20px;
				display: block;
			    text-align: center;
			    background: #333;
			    color: #fff;
			    line-height: 0;
			    padding: 20px 0;
			    border-radius: 40px;

			    @media screen and (min-width: $mihaaru-seperate-break-point) {
			    	top: 42%;
			    }
			}
		}
	}
}

// buttons
.btn {
	border-radius: $mihaaru-border-radius;
	text-transform: uppercase;
	padding: 12px 0;
	color: #fff;

	&:hover {
		color: #fff;
	}
}
.btn-main {
	background: #333;
}
.btn-facebook {
	background: #3b5998;
}
.btn-twitter {
	background: #00b6f1;
}
.btn-google {
	background: #df4a32;
}
.btn-linkedin {
	background: #007bb6;
}
.btn-social-connect {
	background: #9B9B9B;
}
.btn-social-disconnect {
	background: #4990E2;
}

// social box
.social {
	margin-bottom: 40px;

	p {
		display: block;
		margin: 15px 0;
	}
}

// profile picture
.picture-wrap {
	margin: 0 15px 15px 15px;
	text-align: center !important;

	.picture {
		width: 120px;
		height: 120px;
		background: #D8D8D8;
		border: 1px solid #979797;
		margin: 0 auto 10px;

		.text {
			margin: 15px 0;
		}
	}
}

// file upload
#upload_label {
    color: #3097D1 !important;
    text-decoration: none;

    &:hover {
    	text-decoration: underline !important;
    	color: #216a94 !important;
    }
}
#upload_file {
	display: none;
}

// page sub menu
ul.page-submenu {
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
		margin: 0;

		a {
			display: block;
			font-size: 16px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

// footer 
#footer {
	color: $mihaaru-text-color-light;
	font-size: 12px;
	margin: 150px 0 20px 0;
}

