
// Break Point
$mihaaru-min-break-point: 768px;
$mihaaru-max-break-point: 766px;
$mihaaru-seperate-break-point: 992px;

// Body
$body-bg: #fff;

// Borders
$mihaaru-border-color: #D0D0D0;

$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$mihaaru-text-color-dark: #333;
$mihaaru-text-color-light: #888888;
$mihaaru-text-color-link: #4990E2;
$mihaaru-font-slab: 'Slabo 27px', serif;
$font-family-sans-serif: 'Roboto Condensed', sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: $mihaaru-text-color-light;


// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

$mihaaru-border-radius: 3px;

// Panels
$panel-default-heading-bg: #fff;
